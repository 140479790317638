<template>
	<div id="main">
		<h1>Thank you for your order!</h1>
		<p>
			You should receive an order confirmation email soon. If not please
			contact us.
		</p>
		<p>
			<strong>View your order now: </strong>
			<a v-text="orderId" @click="$router.push(`/order/${orderId}`)"></a>
		</p>

		<br />

		<div style="text-align: center">
			<p><strong>How did you hear about Custom Branding PRO?</strong></p>
			<button @click="surveyVote('friend')">From a friend</button>
			<br />
			<br />
			<button @click="surveyVote('facebook')">On Facebook</button>
			<br />
			<br />
			<button @click="surveyVote('webinar')">From a webinar</button>
			<br />
			<br />
			<button @click="surveyVote('google')">Google</button>
			<br />
			<br />
			<button @click="surveyVote('other')">Other</button>
		</div>
	</div>
</template>

<script>
	import { fireGet, firePut, fireAsyncSignIn } from '../db';

	export default {
		name: 'ThankYou',
		props: {
			orderId: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				survey: '001',
				rawSurvey: {}
			};
		},
		mounted() {
			fireAsyncSignIn()
				.then(() => {
					fireGet(
						`survey${this.survey}`,
						`survey${this.survey}`
					).then(data => {
						this.rawSurvey = data;
					});
				})
				.catch(e => {
					console.log(`fireAsyncSignIn Error ${e.message}`);
				});
		},
		methods: {
			surveyVote(answer) {
				this.rawSurvey[answer] = this.rawSurvey[answer] + 1;
				firePut(
					`survey${this.survey}`,
					this.rawSurvey,
					`survey${this.survey}`
				);
			}
		}
	};
</script>

<style scoped>
	#main {
		max-width: 90%;
		margin: 0 auto;
		padding-top: 15vh;
	}

	h1,
	p,
	a {
		color: var(--black);
		text-align: center;
	}

	a {
		color: var(--text);
		text-decoration: underline;
	}

	a:hover {
		cursor: pointer;
		color: var(--accent-blue);
	}
</style>
